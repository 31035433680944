import React from "react"
import { URL_HOME } from "@data/constants"
import { navigate } from "gatsby"

const isClient = typeof window !== "undefined"

const StudentRedirect = () =>
  isClient ? (window.location.href = URL_HOME) : null

export default StudentRedirect
